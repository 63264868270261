// Non auth public routes
import {
    Login,
    ForgetPassword,
    ForgetVerifyCode,
    ResetPassword,
} from "../pages/Authentication"
// Department
import { Dashboard as DeparmentDashboard } from "pages/DepartmentApp/Dashboard"
import { InventoryRequestListing as DeparmentInventoryRequest } from "pages/DepartmentApp/InventoryRequests"
import {
    Inventory,
    InventoryListingBreakdown,
} from "pages/DepartmentApp/Inventory"
import { ScrapReturnListing as DeparmentScrapReturn } from "pages/DepartmentApp/ScrapReturn"
// Warehouse
import { Dashboard as WarehouseDashboard } from "pages/WarehouseApp/Dashboard"
import { InventoryRequest as WarehouseInventoryRequest } from "pages/WarehouseApp/InventoryRequests"
import {
    PurchaseOrderItemsListing,
    PurchaseOrdersListing,
    RequestItemsListing,
    RequestItemsReceivingListing,
    RequestManagement,
} from "pages/WarehouseApp/RequestManagement"
import { InventoryManagement as WarehouseInventoryManagement } from "pages/WarehouseApp/InventoryManagement"
// Common
import { Settings } from "pages/Settings"
// Utils
import { APP_ROUTES } from "../helpers/routeHelpers"
import { withTitle } from "../hoc"
import { PageTitles } from "../constants"
import RequestsListing from "pages/WarehouseApp/RequestManagement/RequestsListing"
import { TheftListing } from "pages/DepartmentApp/Theft"

const departmentRoutes = [
    {
        path: APP_ROUTES.DASHBOARD,
        component: withTitle({
            component: DeparmentDashboard,
            title: PageTitles.DASHBOARD,
        }),
        exact: true,
    },
    {
        path: APP_ROUTES.SETTINGS,
        component: withTitle({
            component: Settings,
            title: PageTitles.SETTINGS,
            path: APP_ROUTES.SETTINGS,
        }),
        exact: true,
    },
    {
        path: APP_ROUTES.INVENTORY,
        component: withTitle({
            component: Inventory,
            title: PageTitles.INVENTORY,
        }),
        exact: true,
    },
    {
        path: APP_ROUTES.INVENTORY_BREAKDOWN,
        component: withTitle({
            component: InventoryListingBreakdown,
            title: PageTitles.INVENTORY,
        }),
        exact: true,
    },
    {
        path: APP_ROUTES.INVENTORY_REQUEST,
        component: withTitle({
            component: DeparmentInventoryRequest,
            title: PageTitles.INVENTORY_REQUEST,
        }),
        exact: true,
    },
    {
        path: APP_ROUTES.SCRAP_RETURN,
        component: withTitle({
            component: DeparmentScrapReturn,
            title: PageTitles.SCRAP_RETURN,
        }),
        exact: true,
    },
    {
        path: APP_ROUTES.THEFT,
        component: withTitle({
            component: TheftListing,
            title: PageTitles.THEFT,
        }),
        exact: true,
    },
]
const warehouseRoutes = [
    // Dashboard
    {
        path: APP_ROUTES.DASHBOARD,
        component: withTitle({
            component: WarehouseDashboard,
            title: PageTitles.DASHBOARD,
        }),
        exact: true,
    },
    // Settings
    {
        path: APP_ROUTES.SETTINGS,
        component: withTitle({
            component: Settings,
            title: PageTitles.SETTINGS,
            path: APP_ROUTES.SETTINGS,
        }),
        exact: true,
    },
    // Inventory Management
    {
        path: APP_ROUTES.INVENTORY,
        component: withTitle({
            component: WarehouseInventoryManagement,
            title: PageTitles.INVENTORY_MANAGEMENT,
        }),
        exact: false,
    },
    // Inventory Request
    {
        path: APP_ROUTES.INVENTORY_REQUEST,
        component: withTitle({
            component: WarehouseInventoryRequest,
            title: PageTitles.INVENTORY_REQUEST,
        }),
        exact: false,
    },

    // Request Management
    {
        path: APP_ROUTES.REQUEST_MANAGEMENT,
        component: withTitle({
            component: RequestManagement,
            title: PageTitles.REQUEST_MANAGEMENT,
        }),
        exact: false,
    },
    {
        path: APP_ROUTES.REQUEST_MANAGEMENT_REQUESTS,
        component: withTitle({
            component: RequestsListing,
            title: PageTitles.REQUEST_MANAGEMENT,
        }),
        exact: false,
    },
    {
        path: APP_ROUTES.REQUEST_MANAGEMENT_REQUESTS_ITEMS,
        component: withTitle({
            component: RequestItemsListing,
            title: PageTitles.REQUEST_MANAGEMENT,
        }),
        exact: false,
    },
    {
        path: APP_ROUTES.REQUEST_MANAGEMENT_REQUESTS_ITEMS_RECEIVING,
        component: withTitle({
            component: RequestItemsReceivingListing,
            title: PageTitles.REQUEST_MANAGEMENT,
        }),
        exact: false,
    },

    {
        path: APP_ROUTES.REQUEST_MANAGEMENT_POS,
        component: withTitle({
            component: PurchaseOrdersListing,
            title: PageTitles.REQUEST_MANAGEMENT,
        }),
        exact: false,
    },
    {
        path: APP_ROUTES.REQUEST_MANAGEMENT_POS_ITEMS,
        component: withTitle({
            component: PurchaseOrderItemsListing,
            title: PageTitles.REQUEST_MANAGEMENT,
        }),
        exact: false,
    },
]

const publicRoutes = [
    {
        path: APP_ROUTES.LOGIN,
        component: withTitle({
            component: Login,
            title: PageTitles.LOGIN,
        }),
    },
    {
        path: APP_ROUTES.FORGET_PASSWORD,
        component: withTitle({
            component: ForgetPassword,
            title: PageTitles.FORGET_PASSWORD,
        }),
    },
    {
        path: APP_ROUTES.FORGET_VERIFY_CODE,
        component: withTitle({
            component: ForgetVerifyCode,
            title: PageTitles.FORGET_VERIFY_CODE,
        }),
    },
    {
        path: APP_ROUTES.RESET_PASSWORD,
        component: withTitle({
            component: ResetPassword,
            title: PageTitles.RESET_PASSWORD,
        }),
    },
]

export { publicRoutes, departmentRoutes, warehouseRoutes }
