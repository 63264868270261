import React, { useEffect, useState } from "react"
import { Button, ButtonGroup } from "reactstrap"

const GroupedButtons = ({
    handleSetActive = () => {},
    btnConfig = [],
    defaultActive = "",
    btnContainerStyles = {},
    btnContainerClasses = "",
    isReadOnly = false,
}) => {
    const [active, setActive] = useState()

    useEffect(() => {
        setActive(defaultActive)
    }, [defaultActive])

    const handleBtnClick = (id, cb) => {
        setActive(id)
        handleSetActive(id)
        cb()
    }

    return (
        <>
            <ButtonGroup
                style={{ ...btnContainerStyles }}
                className={btnContainerClasses}
            >
                {btnConfig?.map(
                    (
                        {
                            color,
                            onClick,
                            activeId,
                            classes,
                            btnTitle,
                            isOutlined,
                        },
                        ind
                    ) => {
                        return (
                            <Button
                                disabled={isReadOnly && activeId != active}
                                key={ind}
                                color={color}
                                outline={isOutlined}
                                onClick={() =>
                                    handleBtnClick(activeId, onClick)
                                }
                                active={active == activeId}
                                className={classes}
                            >
                                {btnTitle}
                            </Button>
                        )
                    }
                ) || null}
            </ButtonGroup>
        </>
    )
}

export default GroupedButtons
