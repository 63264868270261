import React, { useState } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import { Form, Table } from "reactstrap"
import moment from "moment"

import { MarkItemsReceiving } from "../../../api/api.service"
import { TextInput } from "../../../components/TextInput"
import placeholderImage from "../../../assets/images/image-upload-placeholder.png"
import {
    finalizeMediaUtil,
    initializeMediaUtil,
    uploadOnS3,
} from "../../../utils/mediaUtils"
import { Button } from "../../../components/Button"
import ManifestUpload from "./ManifestUpload"
import { DataTable } from "../../../components/DataTable"
import { ImageTypes } from "../../../constants"
import { convertArrItemsToCommaSeparatedString } from "utils/commonUtils"

const MAX_FILE_SIZE = 5120
const TEMP_STATUS = "Pending"
const MANIFEST_SUPPORTED_FILES = [...ImageTypes, "pdf"]
const COLUMNS = {
    PO_NO: "PO No.",
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    SUBSTITUTE_ITEM: "Substitute Name",
    RECEIVED_QUANTITY: "Received Qty.",
    REQUEST_QUANTITY: "Request Qty.",
    QUANTITY_SUPPLIED: "Quantity Supplied",
    RECEIVED_NOW: "Received Now",
}

const RequestItemsReceivingSection = ({
    selectedPO,
    selectedData = [],
    onModalToggle = () => {},
    onListRefresh = () => {},
}) => {
    const { requestId } = useParams()
    const [itemsBucket, setItemsBucket] = useState(selectedData)
    const [imagePreview, setImagePreview] = useState(placeholderImage)
    const [selectedPONumber] = useState(selectedPO)
    const [imageUploaded, setImageUploaded] = useState(false)
    const [image, setImage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleRemoveImage = () => {
        setImagePreview(placeholderImage)
        setImage("")
        setImageUploaded(false)
    }

    const handleFileChange = e => {
        let file = e.target.files[0]
        if (file) {
            const uploadedFileType = file?.type?.split("/")[1]?.toLowerCase()
            let fileSize = file && file?.size
            fileSize = fileSize / MAX_FILE_SIZE

            if (file && MANIFEST_SUPPORTED_FILES.includes(uploadedFileType)) {
                let reader = new FileReader()
                reader.onload = e => {
                    let imagePreview = e.target?.result
                    setImage(file)
                    setImagePreview(imagePreview)
                }
                reader.readAsDataURL(file)
                setImageUploaded(true)
            } else {
                toast.error("File type is not supported")
                setImageUploaded(false)
                setImage("")
                setImagePreview("")
            }
        }
    }
    const handleMediaUpload = async file => {
        return initializeMediaUtil(file).then(async res => {
            const credentials = res
            await uploadOnS3(file, credentials)
            return await finalizeMediaUtil(credentials?.mediaId)
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (!itemsBucket?.length) {
            return toast.error("No item to proceed !")
        }
        if (itemsBucket?.find(item => item.errorMsg)) {
            return toast.error("Please fill the receive values properly!")
        }
        if (!image) {
            return toast.error("Add manifest file!")
        }

        setIsLoading(true)

        let payload = {
            purchaseOrderId: Number(selectedPONumber.id),
            receivedDate: new Date(),
            warehouseRequestItems: itemsBucket.map(item => ({
                quantity: Number(item.quantity),
                itemsId: Number(item.itemsId),
                quantitySupplied: Number(item.inputQty),
                quantityReceived: Number(item.receivedQuantity),
                warehouseRequestItemId: Number(item.warehouseRequestItemId),
                warehouseRequestId: Number(requestId),
                status: TEMP_STATUS,
                ...(item?.substitute?.substituteId && {
                    substituteId: item.substitute.substituteId,
                }),
            })),
        }

        handleMediaUpload(image)
            .then(res => {
                payload.manifestMediaId = res.id
                MarkItemsReceiving(payload)
                    .then(res => {
                        toast.success("Successfully updated !")

                        onModalToggle()
                        onListRefresh()
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleSetReceivingQty = (evt, index, targetObj) => {
        const modifiedValue = evt.target.value
        let errorToSet = ""
        if (!modifiedValue) {
            errorToSet = "Enter quantity"
        } else if (modifiedValue > targetObj.quantitySupplied) {
            errorToSet = "Can't be greater than supplied qty."
        } else {
            errorToSet = ""
        }

        let indexOfTargetObj = index
        let stateCopy = [...itemsBucket]
        let refObj = (stateCopy[indexOfTargetObj] = {
            ...targetObj,
        })

        refObj.inputQty = modifiedValue
        refObj.errorMsg = errorToSet

        setItemsBucket(stateCopy)
    }

    return (
        <div className="p-4">
            <h3 className="text-center inv-req-popup-heading">
                Receiving Details
            </h3>
            <Form>
                <Table borderless responsive className="receiving-form-table">
                    <tr>
                        <td className="w-25">
                            <label className="d-flex align-items-center receiving-form-label m-0 p-0 ">
                                Received Date
                            </label>
                        </td>
                        <td className="w-75">
                            <TextInput
                                className="m-0 p-0 d-flex align-items-center"
                                value={moment(new Date()).format(
                                    "MMM DD, YYYY"
                                )}
                                disabled
                                id="receiveDate"
                                type="text"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td className="w-25">
                            <label className="d-flex align-items-center receiving-form-label m-0 p-0">
                                PO No.
                            </label>
                        </td>
                        <td className="w-75">
                            <TextInput
                                className="m-0 p-0 d-flex align-items-center"
                                value={selectedPONumber?.poNo}
                                disabled
                                type="text"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td className="d-flex align-items-start w-100">
                            <label className=" receiving-form-label m-0 p-0 ">
                                Upload Manifest
                            </label>
                        </td>
                        <td className="w-75">
                            <ManifestUpload
                                image={imagePreview}
                                onChange={handleFileChange}
                                isNewImageSet={imageUploaded}
                                setImageUploaded={setImageUploaded}
                                removeImageHandler={handleRemoveImage}
                            />
                        </td>
                    </tr>
                </Table>

                <div>
                    <DataTable
                        loading={false}
                        data={itemsBucket}
                        className="bg-header-color mt-3 view-item-detail height-scroll-table p-1"
                        config={[
                            {
                                title: COLUMNS.PO_NO,
                                render: data => (
                                    <span>
                                        {convertArrItemsToCommaSeparatedString(
                                            data.poNo
                                        )}
                                    </span>
                                ),
                            },
                            {
                                title: COLUMNS.SKU_NO,
                                render: data => <span>{data.skuNo}</span>,
                            },
                            {
                                title: COLUMNS.ITEM_NAME,
                                render: data => <span>{data.itemName}</span>,
                            },
                            {
                                title: COLUMNS.SUBSTITUTE_ITEM,
                                render: data =>
                                    data?.substitute?.itemName || "-",
                            },

                            {
                                title: COLUMNS.REQUEST_QUANTITY,
                                render: data => <span>{data.quantity}</span>,
                            },
                            {
                                title: COLUMNS.RECEIVED_QUANTITY,
                                render: data => (
                                    <span>{data.receivedQuantity}</span>
                                ),
                            },
                            {
                                title: COLUMNS.QUANTITY_SUPPLIED,
                                render: data => (
                                    <span>{data.quantitySupplied}</span>
                                ),
                            },
                            {
                                title: COLUMNS.RECEIVED_NOW,
                                render: (data, ind) => {
                                    return (
                                        <>
                                            <div>
                                                <TextInput
                                                    onChange={e =>
                                                        handleSetReceivingQty(
                                                            e,
                                                            ind,
                                                            data
                                                        )
                                                    }
                                                    placeholder="0"
                                                    labelClass="d-none"
                                                    type="number"
                                                    inputClass="view-detail-inv-req-p-ip"
                                                    value={data.inputQty}
                                                    name="price"
                                                    max={data.quantitySupplied}
                                                    disabled={
                                                        !selectedPONumber.id
                                                    }
                                                    step="0.01"
                                                    min={0}
                                                />

                                                <span className="error fs-10">
                                                    {data.errorMsg}
                                                </span>
                                            </div>
                                        </>
                                    )
                                },
                            },
                        ]}
                    />
                </div>

                <div className="mt-3 text-center">
                    <Button
                        type={"submit"}
                        title={"Update"}
                        className="gt-btn-grad-primary p-2 btn-alignment"
                        isLoading={isLoading}
                        onClick={handleSubmit}
                        disabled={!selectedPONumber.id}
                    />
                </div>
            </Form>
        </div>
    )
}

export default RequestItemsReceivingSection
