import React, { useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
import { useHistory } from "react-router-dom"

import ActionEyeIcon from "../../../assets/images/inventory-request/action-eye-icon.png"
import { SearchBox } from "../../../components/SearchBox"
import { Pagination } from "../../../components/Pagination"
import { DataTable } from "../../../components/DataTable"
import { PageLimit } from "../../../components/PageLimit"
import { Button } from "../../../components/Button"
import { GetWarehouseToFinanceRequestsListing } from "../../../api/api.service"
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest"
import { RequestStatus } from "../../../constants"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"
import { AutoComplete } from "../../../components/AutoComplete"
import { Refresh } from "../../../components/Refresh"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import customStyles from "../SelectStyles"
import { convertToSelectOptions } from "../../../utils/commonUtils"

const COLUMNS = {
    REQUEST_NO: "Request No.",
    DATE_OF_REQUEST: "Date of Request",
    DATE_COMPLETED: "Date Completed",
    MANIFEST_UPLOADED: "Manifest Uploaded",
    NO_OF_ITEMS: "No. of Items",
    DEPARTMENT: "Station",
    STATUS: "Status",
    ACTION: "Action",
}

const dataKey = "data"
const totalKey = "total"

const RequestsListing = ({}) => {
    const history = useHistory()

    const [statusFilters, setStatusFilters] = useState(null)

    const {
        data,
        page,
        pageSize,
        isFetching,
        searchText,
        total,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetWarehouseToFinanceRequestsListing,
        params: {
            ...(statusFilters?.value && {
                requestStatus: statusFilters?.value,
            }),
        },
        deps: [statusFilters],
        dataKey: dataKey,
        totalKey: totalKey,
    })

    const handleSelectStatusOption = option => {
        setStatusFilters(option)
    }

    const handleNavigateToRequestItems = data => {
        history.push(
            getRoute(APP_ROUTES.REQUEST_MANAGEMENT_REQUESTS_ITEMS, {
                requestId: data.warehouseRequestId,
            })
        )
    }

    return (
        <>
            <div className="gt-business-accounts">
                <Container fluid>
                    <Row>
                        <Col className="col-12 px-2 order-management-con">
                            <Card className="mb-5">
                                <Refresh
                                    onClick={request}
                                    isDisable={isFetching}
                                />
                                <CardBody className="p-2 p-md-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <PageLimit
                                            pageSize={pageSize}
                                            changePageSizeHandler={
                                                onChangePageSize
                                            }
                                            rowLimit={pageSize}
                                            typoClasses="page-limit-typo"
                                            selectClasses="page-limit-select"
                                        />

                                        {/* <div className="d-flex flex-wrap">
                                            <div
                                                style={{ width: "200px" }}
                                                className="me-2"
                                            > */}
                                        <div className=" d-flex gap-2 align-items-center">
                                            <AutoComplete
                                                customStyles={
                                                    customStyles.WarehouseIM
                                                }
                                                value={statusFilters}
                                                isClearable
                                                onChange={val =>
                                                    handleSelectStatusOption(
                                                        val
                                                    )
                                                }
                                                isSearchable={false}
                                                placeholder="Select status"
                                                classNamePrefix="status-header-search"
                                                options={convertToSelectOptions(
                                                    RequestStatus
                                                )}
                                                // className="w-100 order-man-select"
                                                filterOption={option =>
                                                    option.value !==
                                                    RequestStatus.PENDING
                                                }
                                            />
                                            {/* </div> */}
                                            <SearchBox
                                                isHideLable
                                                searchText={searchText}
                                                onChange={handleSearchChange}
                                                inputClasses="table-main-search-input wh-im-search-input"
                                                placeholder="Search..."
                                            />
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        config={[
                                            {
                                                title: COLUMNS.REQUEST_NO,
                                                render: data =>
                                                    data.warehouseRequestId,
                                            },
                                            {
                                                title: COLUMNS.DATE_OF_REQUEST,
                                                render: data =>
                                                    convertDateTime({
                                                        date: data.createdAt,
                                                        customFormat:
                                                            dateTimeFormat.casitaCustomDateFormat,
                                                        dateOnly: true,
                                                    }),
                                            },

                                            {
                                                title: COLUMNS.DATE_COMPLETED,
                                                render: data =>
                                                    data.requestStatus ==
                                                    RequestStatus.COMPLETED
                                                        ? convertDateTime({
                                                              date: data.updatedAt,
                                                              customFormat:
                                                                  dateTimeFormat.casitaCustomDateFormat,
                                                              dateOnly: true,
                                                          })
                                                        : "-",
                                            },

                                            {
                                                title: COLUMNS.MANIFEST_UPLOADED,
                                                render: data => (
                                                    <span
                                                    // className="clickable-link-text"
                                                    >
                                                        {data.manifestCount}
                                                    </span>
                                                ),
                                            },

                                            {
                                                title: COLUMNS.NO_OF_ITEMS,
                                                render: data =>
                                                    data.orders.length,
                                            },

                                            {
                                                title: COLUMNS.STATUS,
                                                render: data => {
                                                    return (
                                                        <div
                                                            className={`inv-req-popup-status ${RequestStatus.getLabelClass(
                                                                data.requestStatus
                                                            )}`}
                                                        >
                                                            {RequestStatus.getDisplayTextKey(
                                                                data.requestStatus
                                                            )}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                className: "text-center",
                                                title: COLUMNS.ACTION,
                                                render: data => {
                                                    return (
                                                        <span
                                                            className="text-success pointer"
                                                            onClick={() => {
                                                                handleNavigateToRequestItems(
                                                                    data
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    ActionEyeIcon
                                                                }
                                                            />
                                                        </span>
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                            <div className="text-center d-flex justify-content-center align-items-center">
                                <Button
                                    size="lg"
                                    className="gt-btn-grad-primary table-bottom-btn btn-alignment ln-height-1"
                                    title="Back to Main"
                                    onClick={history.goBack}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default RequestsListing
