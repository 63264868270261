import React, { useState } from "react"
import { CardBody, Card } from "reactstrap"
import { toast } from "react-hot-toast"

import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import { Button } from "components/Button"
import { SearchBox } from "components/SearchBox"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import {
    GetScrapReturnListing,
    GetScrapReturnRequestById,
    WarehouseInventoryListing,
    CreateScrapReturnRequest,
} from "api/api.service"

import { Refresh } from "components/Refresh"
import { UserTypes } from "constants"
import TheftForm from "pages/Common/Theft/TheftForm"
import { truncateString } from "utils/commonUtils"

const COLUMNS = {
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    WAREHOUSE: "Warehouse Name",
    TYPE: "Type",
    QUANTITY: "Quantity",
    DESCRIPTION: "Issues",
    DATE: "Date",
    ACTION: "Action",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const InventoryReqWarehouseTheftListing = ({}) => {
    const [isFetchingRequest, setIsFetchingRequest] = useState(false)
    const [isSubmittingScrap, setIsSubmittingScrap] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [selectedListItem, setSelectedListItem] = useState("")

    const {
        page,
        data,
        total,
        pageSize,
        isFetching,
        searchText,
        request,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetScrapReturnListing,
        params: {
            requestType: "Theft",
        },
        deps: [],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const handleViewScrapDetails = async (scrapId = null) => {
        if (!scrapId) return
        setIsReadOnly(true)

        let paramsObj = {
            type: UserTypes.WAREHOUSE,
        }

        setToggleModal(true)

        try {
            setIsFetchingRequest(true)
            let res = await GetScrapReturnRequestById(scrapId, paramsObj)
            setSelectedListItem(res)
        } catch (err) {
        } finally {
            setIsFetchingRequest(false)
        }
    }

    const handleCreateScrapReturnRequest = () => {
        setSelectedListItem("")
        setToggleModal(!toggleModal)
        setIsReadOnly(false)
    }

    const handleSubmitScrap = async payload => {
        try {
            setIsSubmittingScrap(true)
            let res = await CreateScrapReturnRequest(payload)
            toast.success("Request has been created successfully !")
            request()
        } catch (err) {
        } finally {
            setIsSubmittingScrap(false)
            setToggleModal(!toggleModal)
        }
    }

    return (
        <>
            <Card className="mb-5">
                <Refresh onClick={request} isDisable={isFetching} />
                <CardBody className="p-2 p-md-4">
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="d-flex mt-lg-0 me-0  wh-im-header-inner-btns-con ">
                            <Button
                                styles={{ height: "auto" }}
                                title="Create Theft Request"
                                className="gt-btn-grad-primary px-2 px-md-4 wh-im-header-inner-btns ms-0 gt-btn-grad-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCreateScrapReturnRequest()}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row mb-3 mb-md-0">
                        <PageLimit
                            pageSize={pageSize}
                            changePageSizeHandler={onChangePageSize}
                            rowLimit={pageSize}
                            typoClasses="page-limit-typo "
                            selectClasses="page-limit-select"
                            mainClass="mt-0 mb-3 my-md-3"
                        />
                        <div className="d-flex align-items-center justify-content-center  ir-warehouse-con">
                            <SearchBox
                                searchText={searchText}
                                onChange={handleSearchChange}
                                inputClasses="table-main-search-input wh-im-search-input me-2 ir-warehouse-search"
                            />
                        </div>
                    </div>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        tableClasses="inv-req-scrapreturn-table"
                        config={[
                            {
                                title: COLUMNS.SKU_NO,
                                render: data => {
                                    return (
                                        <>
                                            <div
                                                onClick={() =>
                                                    setInventoryDetailsModal(
                                                        true
                                                    )
                                                }
                                            >
                                                {data.skuNo || "-"}
                                            </div>
                                        </>
                                    )
                                },
                            },
                            {
                                title: COLUMNS.ITEM_NAME,
                                render: data => {
                                    return data?.itemName || "-"
                                },
                            },
                            {
                                title: COLUMNS.WAREHOUSE,
                                render: data => {
                                    return (
                                        <>
                                            {data?.warehouse?.warehouseName ||
                                                "-"}
                                        </>
                                    )
                                },
                            },

                            {
                                title: COLUMNS.QUANTITY,
                                render: data => {
                                    return <>{data.quantity || "-"}</>
                                },
                            },
                            {
                                title: COLUMNS.DESCRIPTION,
                                render: data =>
                                    truncateString(data?.issue, 50) || "-",
                            },

                            {
                                title: COLUMNS.DATE,
                                render: data => {
                                    return (
                                        convertDateTime({
                                            date: data.theftDate,
                                            customFormat:
                                                dateTimeFormat.casitaCustomDateFormatOnly,
                                            dateOnly: true,
                                        }) || "-"
                                    )
                                },
                            },
                            {
                                className: "text-center",
                                title: COLUMNS.ACTION,
                                render: data => {
                                    return (
                                        <span
                                            className="text-success pointer"
                                            onClick={() =>
                                                handleViewScrapDetails(data.id)
                                            }
                                        >
                                            <img src={ActionEyeIcon} />
                                        </span>
                                    )
                                },
                            },
                        ]}
                    />
                    <Pagination
                        currentPage={page}
                        totalData={total}
                        onPageClick={handlePageClick}
                        rowLimit={pageSize}
                    />
                </CardBody>
            </Card>
            <Modal
                isOpen={toggleModal}
                handleModalToggling={() => setToggleModal(false)}
                sizeClasses="10"
            >
                <TheftForm
                    quantityKey="quantity"
                    data={selectedListItem}
                    closeModal={() => setToggleModal(false)}
                    isReadOnly={isReadOnly}
                    isSubmitting={isSubmittingScrap}
                    apiFunction={WarehouseInventoryListing}
                    onSubmit={handleSubmitScrap}
                />
            </Modal>
        </>
    )
}

export default InventoryReqWarehouseTheftListing
